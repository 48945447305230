import { Col, Row } from 'react-bootstrap';
import React from 'react';
import ContentSection from '../../ContentSection';
import { useTranslation } from 'react-i18next';
import RocketIcon from '../../../assets/icons/font-awesome/solid/rocket.svg';
import MapMarkerIcon from '../../../assets/icons/font-awesome/solid/map-marker-alt.svg';
import HourglassIcon from '../../../assets/icons/font-awesome/regular/hourglass.svg';
import GetStartedButton from '../../GetStartedButton';

function Features() {
  const { t } = useTranslation('BingRankChecker');

  const data = [
    {
      icon: RocketIcon,
      title: t('Features.feature1.title'),
      description: t('Features.feature1.description'),
    },
    {
      icon: MapMarkerIcon,
      title: t('Features.feature2.title'),
      description: t('Features.feature2.description'),
    },
    {
      icon: HourglassIcon,
      title: t('Features.feature3.title'),
      description: t('Features.feature3.description'),
    },
  ];

  return (
    <ContentSection>
      <Row className="justify-content-md-center">
        {data.map((feature) => (
          <Col lg={4} key={feature.title}>
            <div className="d-flex align-items-center">
              <div className="svg-color-primary mb-5">
                <feature.icon height={36} />
              </div>

              <div className="flex-grow-1 pl-5">
                <h3 className="gr-text-7 text-blackish-blue">
                  {feature.title}
                </h3>
              </div>
            </div>

            <p className="gr-text-9 gr-color-blackish-blue-opacity-7 py-5">
              {feature.description}
            </p>
          </Col>
        ))}
      </Row>

      <div className="text-center mt-10">
        <GetStartedButton />
      </div>
    </ContentSection>
  );
}

export default Features;
