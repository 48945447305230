import { Container } from 'react-bootstrap';
import React from 'react';

function ContentSection(props) {
  const {
    background = 6,
    title,
    description,
    noMargin,
    children,
    ...restProps
  } = props;

  return (
    <section
      className={`content-section py-10 py-lg-15 bg-default-${background}`}
      {...restProps}
    >
      <Container>
        {title && (
          <div
            className={`text-center mb-${noMargin ? 0 : 10} mb-lg-${
              noMargin ? 0 : 15
            }`}
          >
            <h2 className="title gr-text-4 mb-7">{title}</h2>

            {description && <p className="gr-text-8 mb-0">{description}</p>}
          </div>
        )}
        {children}
      </Container>
    </section>
  );
}

export default ContentSection;
