import React from 'react';
import FAQ from '../../FAQ';
import ContentSection from '../../ContentSection';
import { useTranslation } from 'react-i18next';

function BingCheckerFAQ() {
  const { t } = useTranslation('BingRankChecker');
  const items = [
    {
      question: t('BingCheckerFAQ.item1.question'),
      answer: t('BingCheckerFAQ.item1.answer'),
    },
    {
      question: t('BingCheckerFAQ.item2.question'),
      answer: t('BingCheckerFAQ.item2.answer'),
    },
    {
      question: t('BingCheckerFAQ.item3.question'),
      answer: t('BingCheckerFAQ.item3.answer'),
    },
    {
      question: t('BingCheckerFAQ.item4.question'),
      answer: t('BingCheckerFAQ.item4.answer'),
    },
    {
      question: t('BingCheckerFAQ.item5.question'),
      answer: t('BingCheckerFAQ.item5.answer'),
    },
  ];

  return (
    <ContentSection background={2}>
      <FAQ items={items} />
    </ContentSection>
  );
}

export default BingCheckerFAQ;
