import React from 'react';
import { Row, Col } from 'react-bootstrap';
import GetStartedButton from '../../GetStartedButton';
import { useTranslation } from 'react-i18next';
import { StaticImage } from 'gatsby-plugin-image';
import ContentSection from '../../ContentSection';

const Hero = () => {
  const { t } = useTranslation('BingRankChecker');
  const title = t('Hero.title');
  const description = t('Hero.description');

  return (
    <ContentSection>
      <Row
        className="align-items-center pt-20 pb-15"
        itemScope={true}
        itemType="https://schema.org/ImageObject"
      >
        <Col lg="7" className="order-lg-last">
          <StaticImage
            src="../../../assets/image/bing-rank-checker/bing1_web.png"
            width={635}
            sizes="(min-width: 635px) 635px, (min-width: 360px) 330px, 100vw"
            breakpoints={[330, 635, 990, 1270]}
            loading="eager"
            placeholder="blurred"
            alt={title}
            className="shadow-1"
            itemProp="contentUrl"
          />
        </Col>

        <Col lg="5" className="pt-10">
          <h1 className="gr-text-2 font-weight-bold" itemProp="name">
            {title}
          </h1>
          <p className="gr-text-8 mt-5" itemProp="description">
            {description}
          </p>

          <div className="mt-lg-20">
            <GetStartedButton variant={2} utmTerm="main_cta" />
          </div>
        </Col>
      </Row>
    </ContentSection>
  );
};

export default Hero;
