import React from 'react';
import Pricing from '../components/Pricing';
import MetaTags from '../components/Seo/MetaTags';
import { useTranslation } from 'react-i18next';
import Hero from '../components/pages/BingRankChecker/Hero';
import WhatBingChecker from '../components/pages/BingRankChecker/WhatBingChecker';
import WebsiteSeo from '../components/pages/BingRankChecker/WebsiteSeo';
import WhyBingChecker from '../components/pages/BingRankChecker/WhyBingChecker';
import Features from '../components/pages/BingRankChecker/Features';
import Features2 from '../components/pages/BingRankChecker/Features2';
import BingCheckerFAQ from '../components/pages/BingRankChecker/BingCheckerFAQ';
import Brands from '../components/Brands';
import StructuredService from '../components/Seo/StructuredService';
import StructuredAggregateRating from '../components/Seo/StructuredAggregateRating';

const BingRankChecker = () => {
  const { t } = useTranslation('BingRankChecker');

  return (
    <>
      <MetaTags title={t('seo.title')} description={t('seo.description')} />
      <StructuredService name="bing rank checker" />
      <StructuredAggregateRating
        name="SpySERP bing rank checker tool"
        description="To track your website rankings on Bing we recommend using SpySERP for a few reasons. You need to watch and analyze your site's keywords frequently to stay on the top of web searches on Bing or any other search engine. Bing Rank Checker and Bing Rank Tracker allow you to be up to date and track your website's position constantly. Comparing your SEO rankings with your competitors is essential if you do not want to lag back on potential leads. With SpySERP, comparing websites and analyzing keywords is a piece of cake. Through its sophisticated algorithms, SpySERP helps check your website ranking on Bing based on the query searched."
        makesOffer="bing rank checker"
        knowsAbout="bing rank checker tool"
        ratingValue={5}
        bestRating={5}
        ratingCount={24}
      />
      <Hero />
      <WhatBingChecker />
      <WebsiteSeo />
      <WhyBingChecker />
      <Features />
      <Pricing />
      <Features2 />
      <Brands />
      <BingCheckerFAQ />
    </>
  );
};

export default BingRankChecker;
