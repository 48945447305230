import React from 'react';
import { useTranslation } from 'react-i18next';
import { StaticImage } from 'gatsby-plugin-image';
import ContentSection from '../../ContentSection';

const WhatBingChecker = () => {
  const { t } = useTranslation('BingRankChecker');
  const title = t('WhatBingChecker.title');
  const description = t('WhatBingChecker.description');

  return (
    <ContentSection
      background={2}
      itemScope={true}
      itemType="https://schema.org/ImageObject"
    >
      <div className="text-center">
        <h2 className="title gr-text-3 mb-7 mt-7" itemProp="name">
          {title}
        </h2>
        <p className="gr-text-8" itemProp="description">
          {description}
        </p>
      </div>

      <div
        className="px-md-5"
        data-aos="zoom-in"
        data-aos-duration="1000"
        data-aos-delay="500"
      >
        <StaticImage
          src="../../../assets/image/bing-rank-checker/bing.png"
          width={1086}
          sizes="(min-width: 1086px) 1086px, (min-width: 360px) 330px, 100vw"
          breakpoints={[330, 635, 990, 1086, 1780]}
          placeholder="blurred"
          alt={title}
          className="shadow-1"
          itemProp="contentUrl"
        />
      </div>
    </ContentSection>
  );
};

export default WhatBingChecker;
