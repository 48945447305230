import { Col, Row } from 'react-bootstrap';
import React from 'react';
import ContentSection from '../../ContentSection';
import { useTranslation } from 'react-i18next';
import SearchIcon from '../../../assets/icons/font-awesome/solid/search.svg';
import MapMarkerIcon from '../../../assets/icons/font-awesome/solid/map-marker-alt.svg';
import ChartAreaIcon from '../../../assets/icons/font-awesome/regular/chart-area.svg';
import ObjectGroupIcon from '../../../assets/icons/font-awesome/regular/object-group.svg';
import LinkIcon from '../../../assets/icons/font-awesome/regular/link.svg';
import PieChartIcon from '../../../assets/icons/font-awesome/solid/chart-pie-alt.svg';

function Features2() {
  const { t } = useTranslation('BingRankChecker');
  const title = t('Features2.title');
  const description = t('Features2.description');

  const data = [
    {
      icon: SearchIcon,
      title: t('Features2.feature1.title'),
      description: t('Features2.feature1.description'),
    },
    {
      icon: MapMarkerIcon,
      title: t('Features2.feature2.title'),
      description: t('Features2.feature2.description'),
    },
    {
      icon: ChartAreaIcon,
      title: t('Features2.feature3.title'),
      description: t('Features2.feature3.description'),
    },
    {
      icon: ObjectGroupIcon,
      title: t('Features2.feature4.title'),
      description: t('Features2.feature4.description'),
    },
    {
      icon: LinkIcon,
      title: t('Features2.feature5.title'),
      description: t('Features2.feature5.description'),
    },
    {
      icon: PieChartIcon,
      title: t('Features2.feature6.title'),
      description: t('Features2.feature6.description'),
    },
  ];

  return (
    <ContentSection title={title} description={description}>
      <Row className="justify-content-md-center">
        {data.map((feature) => (
          <Col lg={4} key={feature.title} className="py-5">
            <div className="d-flex">
              <div className="svg-color-primary" style={{ width: 36 }}>
                <feature.icon height={36} />
              </div>

              <div className="flex-grow-1 pl-5">
                <h3 className="gr-text-7 text-blackish-blue">
                  {feature.title}
                </h3>
              </div>
            </div>

            <p className="gr-text-9 gr-color-blackish-blue-opacity-7 py-5 pl-13">
              {feature.description}
            </p>
          </Col>
        ))}
      </Row>
    </ContentSection>
  );
}

export default Features2;
